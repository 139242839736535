<template>
	<section id="blog" class="flex flex-col justify-center">
		<BaseIntestazione :intestazione="intestazione" data-aos="fade-left" />
		<div class="grid gap-8 md:grid-cols-2 xl:grid-cols-3">
			<article
				v-for="(article, i) of articles"
				:key="article.titolo"
				class="mb-8 flex w-full max-w-full flex-col"
				:data-aos-delay="i * 50"
				data-aos="fade-left"
			>
				<HomeBlogImages :images="article.images" />
				<div class="flex flex-grow">
					<div class="triangle" />
					<div
						class="text flex flex-col justify-between rounded-xl rounded-t-none border border-gray-50 px-4 py-6 shadow-xl"
					>
						<div>
							<div class="flex gap-2">
								<span
									class="mb-4 inline-block border-b-2 border-rose-900 text-xs font-bold capitalize transition-colors hover:text-rose-900"
								>
									Formazione
								</span>
							</div>

							<span
								class="mb-4 block text-2xl font-black leading-tight transition-colors hover:text-rose-900 hover:underline"
								v-text="article.titolo"
							/>

							<p class="mb-4">{{ article.descrizione_breve }}</p>
						</div>
						<div v-if="false">
							<span
								class="mt-2 inline-block border-b border-transparent pb-1 text-base font-black uppercase text-rose-900 transition-colors hover:border-rose-900"
							>
								Continua a leggere ->
							</span>
						</div>
					</div>
				</div>
			</article>
		</div>
	</section>
</template>

<script setup lang="ts">
const intestazione = reactive({
	name: 'Blog',
	title: 'Le ultime notizie',
	description: ''
});
const articles = reactive([
	{
		images: [
			{
				url: '/images/blog/8.webp',
				alt: 'Corso di formazione taglio base geometrica applicata'
			}
		],
		tags: ['formazione'],
		titolo: 'Corso di formazione taglio base geometrica applicata',
		descrizione_breve:
			'Questo corso fornisce una solida base teorica e pratica nel taglio dei capelli utilizzando principi geometrici applicati. Gli studenti impareranno le tecniche di taglio più avanzate e come applicarle per creare look moderni e alla moda.'
	},
	{
		images: [
			{url: '/images/blog/1.webp', alt: 'protoplasmina prestige oil'},
			{url: '/images/blog/2.webp', alt: 'protoplasmina prestige oil'}
		],
		tags: ['formazione'],
		titolo: 'Evento di formazione Giarre 2022',
		descrizione_breve:
			"Partecipa al nostro esclusivo evento di formazione a Giarre nel 2022, dove esperti del settore condivideranno le ultime tendenze e tecniche nel campo dell'hair styling. È un'opportunità imperdibile per rimanere aggiornati sulle ultime novità e per connettersi con altri professionisti del settore."
	},
	{
		images: [{url: '/images/blog/9.webp'}],
		tags: ['formazione'],
		titolo: 'Corso di formazione Strutture del taglio 2022',
		descrizione_breve:
			'Questo corso approfondisce lo studio delle diverse strutture dei capelli e come queste influenzano il processo di taglio. Gli studenti impareranno a riconoscere e adattarsi alle diverse texture dei capelli per ottenere risultati ottimali in base alle esigenze dei clienti.'
	},
	{
		images: [{url: '/images/blog/3.webp'}, {url: '/images/blog/4.webp'}, {url: '/images/blog/5.webp'}],
		tags: ['formazione'],
		titolo: 'Corso di formazione ABC del Taglio 2023',
		descrizione_breve:
			'Il corso ABC del Taglio del 2023 è progettato per coloro che desiderano acquisire competenze avanzate nel taglio dei capelli. Attraverso lezioni pratiche e teoriche, gli studenti impareranno le tecniche più innovative e creative per creare look personalizzati e di tendenza.'
	}
]);
</script>

<style scoped>
.triangle {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 20px 20px 0;
	border-color: transparent #1a202c transparent transparent;
}
</style>
