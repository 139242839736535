<template>
	<Splide v-if="images.length > 1" :options="options" aria-label="Products piastra ghd">
		<SplideSlide v-for="(image, i) of images" :key="`image${i}`">
			<BasePicture
				class="aspect-square w-full rounded-b-none"
				:src="image.url"
				alt="protoplasmina prestige oil"
				title="protoplasmina prestige oil"
				aria-label="protoplasmina prestige oil"
				width="600"
				height="600"
			/>
		</SplideSlide>
	</Splide>

	<BasePicture
		v-else-if="images.length === 1"
		class="aspect-square w-full rounded-b-none"
		:src="images[0].url"
		alt="protoplasmina prestige oil"
		title="protoplasmina prestige oil"
		aria-label="protoplasmina prestige oil"
		width="600"
		height="600"
	/>

	<BasePicture v-else class="max-h-64 w-full rounded-b-none" src="/images/AnyConv.com__IMG-20230920-WA0006.webp" />
</template>

<script setup lang="ts">
type IImages = {
	url: string;
};

const options = reactive({
	type: 'loop',
	perPage: 1,
	rewind: true,
	autoplay: true,
	interval: 3000,
	arrows: false,
	pagination: false
});

defineProps<{images: IImages[]}>();
</script>
