<template>
	<section id="contact">
		<BaseIntestazione :intestazione="intestazione" data-aos="fade-up" />
		<div class="my-4">
			<UForm
				id="contact-form"
				:schema="schema"
				:state="state"
				class="my-4 w-full rounded-xl p-8 shadow-2xl md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40"
				@submit.prevent="onSubmit"
			>
				<div class="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
					<UFormGroup label="Nome" name="name">
						<UInput v-model="state.name" />
					</UFormGroup>
					<UFormGroup label="Cognome" name="surname">
						<UInput v-model="state.surname" />
					</UFormGroup>
					<UFormGroup label="Email" name="email">
						<UInput v-model="state.email" />
					</UFormGroup>
					<UFormGroup label="Telefono" name="phone">
						<UInput v-model="state.phone" />
					</UFormGroup>

					<UFormGroup label="Messaggio" name="message" class="md:col-span-2">
						<UTextarea v-model="state.message" />
					</UFormGroup>

					<UButton type="submit" variant="link" class="relative">
						<NuxtLink
							title="Contatti"
							:to="`mailto:salvobeato@hotmail.it?subject=Email+inviata+da+salvobeatoparrucchieri.com;body=${body}`"
							class="flex w-full items-center justify-center gap-4 rounded-md border border-black-200 px-6 py-3 text-black-900 transition-colors hover:bg-rose-900 hover:text-gray-50"
						>
							<Icon name="solar:chat-line-linear" class="h-6 w-6" />
							Invia il messaggio
						</NuxtLink>
					</UButton>
				</div>
			</UForm>

			<div class="ms-auto w-full rounded-xl border border-rose-900 bg-rose-900 px-8 py-12 lg:-mt-96 lg:w-2/6">
				<div class="flex flex-col text-white">
					<div class="mb-4 flex items-center gap-4">
						<Icon name="solar:home-angle-linear" class="h-6 w-6" />
						<p class="text-2xl">Orari</p>
					</div>

					<div class="grid gap-2">
						<div class="flex justify-between">
							<span>Lunedi</span>
							<span>Chiuso</span>
						</div>
						<div class="flex justify-between">
							<span>Mar - Ven</span>
							<span>
								08:30 - 12:30
								<br />
								15:30 - 20:00
							</span>
						</div>
						<div class="flex justify-between">
							<span>Sabato</span>
							<span>08:30 - 20:00</span>
						</div>
						<div class="flex justify-between">
							<span>Domenica</span>
							<span>Chiuso</span>
						</div>
					</div>

					<div class="my-4 grid gap-4">
						<div class="flex items-center gap-4">
							<Icon name="solar:home-angle-2-linear" class="h-6 w-6" />
							<p class="text-2xl">Salone</p>
						</div>
						<div>
							<NuxtLink
								title="indirizzo"
								aria-label="indirizzo"
								rel="noopener noreferrer"
								target="_blank"
								href="https://maps.google.com/maps/dir//Via+G.+Boccaccio,+104C+95047+Patern%C3%B2+CT/@37.5716368,14.9064986,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x131150e308e22149:0x70d90b827bee300e"
							>
								Via Giovanni Boccaccio, 104/C - 95047 Paternò (CT)
							</NuxtLink>
						</div>
					</div>

					<div class="my-4 grid w-2/3 gap-4 lg:w-1/2">
						<div class="flex items-center gap-4">
							<Icon name="solar:phone-calling-linear" class="h-6 w-6" />
							<p class="text-2xl">Contatti</p>
						</div>
						<div>
							<p>
								Tel:
								<a title="telefono" aria-label="telefono" href="tel:+393405996490">+39 340 5996490</a>
							</p>
							<p class="my-2">
								Email:
								<a title="primary mail" aria-label="primary mail" href="mailto:salvobeato@hotmail.it">
									salvobeato@hotmail.it
								</a>
							</p>
							<p>P. Iva 04921890879</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="relative flex min-h-[600px] w-full items-end justify-start overflow-hidden rounded-lg bg-gray-300 p-10"
		>
			<iframe
				width="100%"
				height="100%"
				class="absolute inset-0"
				loading="eager"
				referrerpolicy="no-referrer-when-downgrade"
				frameborder="0"
				title="map"
				marginheight="0"
				marginwidth="0"
				scrolling="no"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.2964359144808!2d14.904309915311824!3d37.57163677979705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x131150e308e22149%3A0x70d90b827bee300e!2sVia%20G.%20Boccaccio%2C%20104C%2C%2095047%20Patern%C3%B2%20CT!5e0!3m2!1sit!2sit!4v1678464925351!5m2!1sit!2sit"
			/>
			<div class="relative flex flex-wrap rounded-xl bg-white py-6 shadow-md dark:bg-rose-900">
				<div class="px-6 lg:w-1/2">
					<h2 class="title-font font-semibold tracking-widest">Informazioni</h2>
					<p class="mt-1">Via Giovanni Boccaccio, 104/C - 95047 Paternò (CT)</p>
					<p class="mt-1">P.I. 04921890879</p>
				</div>
				<div class="mt-4 px-6 lg:mt-0 lg:w-1/2">
					<h2 class="title-font font-semibold tracking-widest">EMAIL</h2>
					<a href="mailto:salvobeato@hotmail.it">salvobeato@hotmail.it</a>
					<h2 class="title-font mt-4 font-semibold tracking-widest">Telefono</h2>
					<a href="tel:+39 340 5996490" rel="noopener noreferrer">+39 340 5996490</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup lang="ts">
import {object, string} from 'yup';
import {phoneRegExp} from '@/constants';

const toast = useToast();

const intestazione = ref({
	title: 'Hai delle domande?',
	name: 'Contatti',
	description:
		'Siamo sempre felici di rispondere alle vostre domande e di fornire assistenza personalizzata. Se desiderate prenotare un appuntamento, richiedere informazioni sui nostri servizi o semplicemente lasciare un feedback, non esitate a contattarci.'
});

const schema = object({
	name: string().min(3, 'Il nome deve essere di almeno 3 caratteri').required('Il nome è obbligatorio'),
	surname: string().min(3, 'Il cognome deve essere di almeno 3 caratteri').required('Il cognome è obbligatorio'),
	email: string().email('Email non valida').required('Email obbligatoria'),
	phone: string().matches(phoneRegExp, 'Numero di telefono non valido').required('Numero di telefono obbligatorio'),
	message: string().max(500, 'Il messaggio deve essere di al massimo 500 caratteri')
});

const state = reactive({
	name: undefined,
	surname: undefined,
	email: undefined,
	phone: undefined,
	message: undefined
});

/**
 * @description Invia il form
 */
async function onSubmit() {
	try {
		await schema.validate(state);
	} catch {
		toast.add({
			id: 'error',
			color: 'red',
			title: 'Ci sono dei errori si prega di controllare!'
		});
	}
}

const body = computed(
	() =>
		`Nome: ${state.name} ${state.surname}%0D%0AEmail: ${state.email}%0D%0ATelefono: ${state.phone}%0D%0AMessaggio: ${state.message}`
);
</script>
