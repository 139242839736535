<template>
	<section id="faq">
		<BaseIntestazione :intestazione="intestazione" data-aos="fade-left" />
		<div class="grid items-center gap-4 lg:grid-cols-3">
			<div class="col-span-2" data-aos-delay="100" data-aos="fade-right">
				<UAccordion
					variant="outline"
					size="xl"
					:items="faqs"
					class="font-semibold"
					:ui="{
						wrapper: 'w-full flex flex-col font-semibold'
					}"
				/>
			</div>
			<BasePicture
				data-aos-delay="100"
				data-aos="fade-right"
				title="faq"
				aria-label="faq"
				src="/images/scrabble-4957948_1280.jpg"
				alt="faq"
				format="webp"
				width="600"
				class="hidden h-full w-[600px] rounded-xl lg:block"
			/>
		</div>
	</section>
</template>

<script setup lang="ts">
const intestazione = ref({
	name: 'F.A.Q',
	title: 'Hai domande sui nostri servizi? Qui troverai le risposte alle domande più frequenti!',
	description:
		'La sezione FAQ è pensata per aiutarti a trovare le risposte alle domande più frequenti dei nostri clienti. Qui troverai informazioni sui nostri servizi, sui nostri prodotti, sui nostri prezzi e molto altro ancora.La sezione FAQ è pensata per aiutarti a trovare le risposte alle domande più frequenti dei nostri clienti. Qui troverai informazioni sui nostri servizi, sui nostri prodotti, sui nostri prezzi e molto altro ancora.'
});

const faqs = reactive([
	{
		id: '1',
		label: 'Dove si trova Salvo Beato Moda & Stile Parrucchieri?',
		content: 'Salvo Beato Moda & Stile Parrucchieri si trova a Paternò (CT) - Via Giovanni Boccaccio 104/C'
	},
	{
		id: '2',
		label: 'Quali sono i vostri orari di apertura?',
		content:
			"L'orario di apertura di Salvo Beato Moda & Stile Parrucchieri è: da Martedì a Venerdì: 08:30 - 12:30, da Martedì a Venerdì: 15:30 - 20:00, Sabato: 08:30 - 20:00"
	},
	{
		id: '3',
		label: 'Quali sono i vostri servizi di taglio e colore?',
		content:
			'Salvo Beato Moda & Stile Parrucchieri offre i seguenti servizi: Taglio Personalizzato per Uomo, Taglio Personalizzato per Donna, Colorazione dei Capelli, Acconciature da Sposa, Trattamenti Tricologici, Tricologia, Studio Immagine Spose'
	},
	{
		id: '4',
		label: 'Quali sono le opinioni degli utenti di Salvo Beato Moda & Stile Parrucchieri?',
		content:
			'Leggi le recensioni di Salvo Beato Moda & Stile Parrucchieri oppure lascia la tua opinione su Paginegialle.it'
	},
	{
		id: '5',
		label: 'Come posso prenotare un appuntamento?',
		content:
			'Puoi contattare Salvo Beato Moda & Stile Parrucchieri tramite email: salvobeato@hotmail.it o telefono: 340 5996490'
	},
	{
		id: '6',
		label: 'Quali sono le modalità di pagamento accettate?',
		content: 'Accettiamo pagamenti con carta e contanti'
	},
	{
		id: '7',
		label: 'Come posso prenotare un appuntamento?',
		content:
			'Puoi contattare Salvo Beato Moda & Stile Parrucchieri tramite email: salvobeato@hotmail.it o telefono: 340 5996490'
	}
]);
</script>
