<template>
	<section id="services">
		<BaseIntestazione :intestazione="intestazione" data-aos="fade-up" />

		<div class="grid gap-6 md:grid-cols-2">
			<HomeServicesCard
				v-for="(service, i) of servizi"
				:key="'ser' + i"
				:data-aos-delay="i * 50"
				data-aos="fade-left"
				:service="service"
				:reverse="i % 2 == 1"
			/>
		</div>
	</section>
</template>

<script setup lang="ts">
const intestazione = reactive({
	name: 'Servizi',
	title: 'Servizi di Parrucchiere di alta qualità',
	description:
		'Scopri i servizi di Salvo Beato Moda & Stile, il parrucchiere a Paternò specializzato in tagli moda, acconciature e barbering.'
});

const servizi = reactive<IService[]>([
	{
		title: 'hair Taglio Personalizzato per Uomo',
		description: '',
		prezzo: 0,
		categoria: '',
		img: {
			src: '/images/large_3_8cce4a754a.webp',
			alt: ''
		}
	},
	{
		title: 'Acconciature da Sposa',
		description: '',
		prezzo: 0,
		categoria: '',
		img: {
			src: '/images/60155204-B4E4-4E80-B3A0-17C481EDB9B4.webp',
			alt: ''
		}
	},
	{
		title: 'Studio Immagine Spose',
		description: '',
		prezzo: 0,
		categoria: '',
		img: {
			src: '/images/gallery/15.jpg',
			alt: ''
		}
	},
	{
		title: 'Taglio Personalizzato per Donna',
		description: '',
		prezzo: 0,
		categoria: '',
		img: {
			src: '/images/IMG_1657.webp',
			alt: ''
		}
	},
	{
		title: 'Trattamenti Tricologici',
		description: '',
		prezzo: 0,
		categoria: '',
		img: {
			src: '/images/IMG_1598.webp',
			alt: ''
		}
	},
	{
		title: 'Colorazioni personalizzate',
		description: '',
		prezzo: 0,
		categoria: '',
		img: {
			src: '/images/IMG_1257.webp',
			alt: ''
		}
	}
]);
</script>
