<template>
	<header>
		<div class="bg-gray-900 py-2 text-gray-50">
			<div class="mx-auto flex justify-between px-4 md:container xl:px-8">
				<div class="flex gap-2 divide-x divide-gray-50">
					<span class="flex items-center gap-2">
						<Icon name="solar:phone-linear" class="h-4 w-4" />
						<a aria-label="telefono" title="telefono" href="tel:+393405996490">+39 340 5996490</a>
					</span>
					<NuxtLink
						aria-label="Indirizzo"
						target="_blank"
						rel="noopener noreferrer"
						title="Indirizzo"
						:to="mapsLink"
						class="hidden items-center gap-2 ps-2 lg:flex"
					>
						<Icon name="solar:map-point-linear" class="h-4 w-4" />
						Via G. Boccaccio, 104C
					</NuxtLink>
				</div>
				<BaseShareLinks />
			</div>
		</div>

		<nav class="relative mx-auto flex items-center justify-between px-4 py-4 md:container xl:px-8">
			<ul class="hidden gap-4 lg:flex">
				<li v-for="link in links" :key="link.text" class="overflow-hidden">
					<NuxtLink
						:title="`Vai alla sezione ${link.text}`"
						:aria-label="`Vai alla sezione ${link.text}`"
						:to="link.to"
						class="roudned-md block bg-gray-50 px-3 py-1.5 transition-colors hover:bg-gray-100"
					>
						{{ link.text }}
					</NuxtLink>
				</li>
			</ul>

			<UButton class="order-1 lg:hidden" variant="soft" title="Pulsante menu mobile" @click="open = !open">
				<Icon name="solar:hamburger-menu-line-duotone" class="h-6 w-6" />
			</UButton>

			<NuxtLink
				title="Logo, vai alla home page"
				aria-label="Logo, vai alla home page"
				class="text-3xl font-bold leading-none"
				to="/"
			>
				<BasePicture
					src="/images/logo-bianco-261w.webp"
					title="logo image"
					alt="logo image"
					aria-label="logo image"
					width="90"
					height="90"
				/>
			</NuxtLink>

			<ul class="hidden gap-4 lg:flex">
				<li class="overflow-hidden">
					<NuxtLink
						title="Vai alla sezione Contatti"
						aria-label="Vai alla sezione Contatti"
						to="#contact"
						class="roudned-md block bg-gray-50 px-3 py-1.5 transition-colors hover:bg-gray-100"
					>
						Contatti
					</NuxtLink>
				</li>
			</ul>
		</nav>

		<USlideover v-model="open">
			<nav class="flex min-h-full flex-col px-6 py-6 text-gray-900 dark:bg-black dark:text-gray-50">
				<div class="mb-8 flex justify-end">
					<UButton variant="soft" @click="open = false">
						<Icon name="solar:close-circle-linear" class="h-6 w-6" />
					</UButton>
				</div>
				<ul class="flex flex-col gap-2">
					<li v-for="link in links" :key="link.text">
						<NuxtLink
							:aria-label="`Vai alla sezione ${link.text}`"
							:to="link.to"
							class="roudned-md block bg-gray-50 px-3 py-1.5 transition-colors hover:bg-gray-100"
							@click="open = false"
						>
							{{ link.text }}
						</NuxtLink>
					</li>
				</ul>
			</nav>
		</USlideover>
	</header>
</template>

<script setup lang="ts">
const open = ref(false);

const links = ref([
	{
		to: {path: '/', hash: '#whoami'},
		text: 'Chi sono'
	},
	{
		to: {path: '/', hash: '#services'},
		text: 'Servizi'
	}
]);

const mapsLink = 'https://www.google.com/maps?saddr=My+Location&daddr=@37.5716368,14.9064986';
</script>
