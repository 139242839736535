<template>
	<div>
		<SchemaOrgWebPage name="" image="/images/logo-bianco-261w.webp" />

		<BasePage>
			<HomeCover />
		</BasePage>

		<HomeDescription />

		<main>
			<BasePage>
				<LazyHomeProducts />
				<LazyHomeReviews />
				<LazyHomeServices />
				<LazyHomeBlog />
				<LazyHomeGallery />
				<LazyHomeStatistics />
				<LazyHomeFaq />
				<LazyHomeContact />
			</BasePage>
		</main>
	</div>
</template>

<script setup lang="ts">
useSeoMeta({
	title: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	ogTitle: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	ogUrl: '/',
	twitterTitle: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	twitterDescription: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	twitterImage: '/images/logo-bianco-261w.webp',
	description:
		'Cerchi un parrucchiere per uomo e donna a Paternò? Prenota ora presso il salone di Salvo Beato specializzato in tagli moda, acconciature e barbering.',
	ogDescription:
		'Cerchi un parrucchiere per uomo e donna a Paternò? Prenota ora presso il salone di Salvo Beato specializzato in tagli moda, acconciature e barbering.',
	ogImage: '/images/logo-bianco-261w.webp',
	twitterCard: 'summary',
	ogType: 'website',
	ogSiteName: 'Salvo Beato Moda & Stile'
});

useHead({
	link: [
		{rel: 'icon', type: 'image/x-icon', href: '/favicon.ico'},
		{
			rel: 'apple-touch-icon',
			type: 'image/x-icon',
			sizes: '180x180',
			href: '/images/icon/apple-touch-icon.png'
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '32x32',
			href: '/images/icon/favicon-32x32.png'
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '16x16',
			href: '/images/icon/favicon-16x16.png'
		},
		{rel: 'manifest', href: '/site.webmanifest'}
	],
	meta: [
		{name: 'viewport', content: 'width=device-width, initial-scale=1'},
		{name: 'theme-color', content: '#ffffff'},
		{name: 'author', content: 'Salvo Beato'},
		{name: 'robots', content: 'index, follow'},
		{name: 'googlebot', content: 'index, follow'},
		{name: 'rating', content: 'general'},
		{name: 'distribution', content: 'global'},
		{name: 'revisit-after', content: '7 days'}
	]
});
</script>
