
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as index9GOUJNEhhYMeta } from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/pages/index.vue?macro=true";
import { default as component_45stubIhK0gbtKV6Meta } from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubIhK0gbtKV6 } from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___it___default",
    path: "/",
    component: () => import("/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/pages/index.vue")
  },
  {
    name: component_45stubIhK0gbtKV6Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubIhK0gbtKV6
  }
]