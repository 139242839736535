<template>
	<footer
		aria-label="Footer del sito"
		class="px-4 py-16 text-gray-900 sm:px-8 lg:grid lg:grid-cols-7 dark:bg-black dark:text-gray-50"
	>
		<BasePicture
			class="mx-auto mb-12"
			loading="eager"
			src="/images/logo-bianco-261w.webp"
			sizes="90px lg:120px"
			title="logo image"
			alt="logo image"
			aria-label="logo image"
			width="90"
			height="90"
		/>

		<div class="lg:col-span-5 lg:px-8">
			<div class="grid grid-cols-1 gap-8 sm:grid-cols-3">
				<div class="sm:col-span-2">
					<p>
						<span class="text-bold uppercase">Chiama ora</span>
						<a href="tel:+39 340 5996490" class="block text-2xl font-medium hover:opacity-75 sm:text-3xl">
							+39 340 5996490
						</a>
					</p>

					<div class="grid gap-2">
						<div class="flex justify-between">
							<span>Lunedì</span>
							<span>Chiuso</span>
						</div>
						<div class="flex justify-between">
							<span>Mar - Ven</span>
							<span>
								08:30 - 12:30
								<br />
								15:30 - 20:00
							</span>
						</div>
						<div class="flex justify-between">
							<span>Sabato</span>
							<span>08:30 - 20:00</span>
						</div>
						<div class="flex justify-between">
							<span>Domenica</span>
							<span>Chiuso</span>
						</div>
					</div>

					<BaseShareLinks class="mt-8" />
				</div>

				<div class="grid grid-cols-1 gap-4">
					<div>
						<span class="text-bold uppercase">Link utili</span>
						<nav aria-label="Footer Navigation - Links" class="mt-6">
							<ul class="space-y-4 text-sm">
								<li v-for="link in footerLinks" :key="link.text" class="relative overflow-hidden">
									<NuxtLink
										:title="link.title"
										:aria-label="link.ariaLabel"
										:to="link.to"
										class="relative before:absolute before:-bottom-1 before:h-0.5 before:w-full before:-translate-x-full before:bg-gray-900 before:transition-all before:content-[''] hover:before:translate-x-0"
									>
										{{ link.text }}
									</NuxtLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>

			<div class="mt-12 border-t border-gray-100 pt-12">
				<div class="sm:flex sm:items-center sm:justify-between">
					<nav aria-label="Footer Navigation - Support">
						<ul class="flex flex-wrap gap-4 text-xs">
							<li>
								<a
									href="https://www.iubenda.com/privacy-policy/48334167"
									class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
									title="Privacy Policy"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
							</li>
						</ul>
					</nav>

					<p class="mt-8 text-xs sm:mt-0">
						&copy; {{ new Date().getFullYear() }}. Salvo Beato Moda & Stile Parrucchieri. Tutti i diritti
						riservati.
					</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup lang="ts">
const footerLinks = ref([
	{
		to: '#contact',
		text: 'Contatti',
		title: 'Vai alla sezione Contatti',
		ariaLabel: 'Vai alla sezione Contatti'
	},
	{
		to: '#whoami',
		text: 'Chi sono',
		title: 'Vai alla sezione Chi sono',
		ariaLabel: 'Vai alla sezione Chi sono'
	},
	{
		to: '#gallery',
		text: 'Galleria',
		title: 'Vai alla sezione Galleria',
		ariaLabel: 'Vai alla sezione Galleria'
	},
	{
		to: '#services',
		text: 'Servizi',
		title: 'Vai alla sezione Servizi',
		ariaLabel: 'Vai alla sezione Servizi'
	},
	{
		to: '#faq',
		text: 'F.A.Q',
		title: 'Vai alla sezione F.A.Q',
		ariaLabel: 'Vai alla sezione F.A.Q'
	}
]);
</script>
