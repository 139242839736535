<template>
	<div id="gallery" class="grid grid-cols-2 gap-4 md:grid-cols-4">
		<div class="col-span-2 w-full md:col-span-4" data-aos="fade-up">
			<BaseIntestazione :intestazione="intestazione" />
		</div>
		<div v-for="(images, i) of gallery_cols" :key="'gallery_col' + i" class="grid gap-4">
			<div
				v-for="(image, j) of images"
				:key="'image' + j"
				:data-aos-delay="(i + 1) * (j + 1) * 100"
				data-aos="fade-left"
				class="rounded-xl"
				@click="
					() => {
						open = true;
						selectedImage = image;
					}
				"
			>
				<BasePicture
					width="auto"
					height="auto"
					format="webp"
					:src="image"
					class="h-full max-w-full cursor-pointer rounded-lg transition-all hover:grayscale"
					:title="'image' + j"
					:aria-label="'image' + j"
					:alt="'image' + j"
				/>
			</div>
		</div>
		<UModal v-model="open" aria-label="Gallery modal">
			<BasePicture
				width="auto"
				height="auto"
				format="webp"
				class="object-contain"
				alt="Preview Image"
				:src="selectedImage"
			/>
		</UModal>
	</div>
</template>

<script setup lang="ts">
const intestazione = {
	title: 'I miei ultimi lavori',
	name: 'Galleria',
	description: ''
};
const open = ref<boolean>(false);
const selectedImage = ref<string>('');

const gallery_cols = reactive<string[][]>([[], [], [], []]);

let index = 0;

for (const img of Array.from({length: 17}, (_, i) => i + 1).reverse())
	gallery_cols[index++ % 4].push(`/images/gallery/${img}.jpg`);
</script>
