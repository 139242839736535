<template>
	<div>
		<NuxtLayout>
			<NuxtPage />
		</NuxtLayout>
		<UNotifications />
	</div>
</template>

<script setup lang="ts">
const colorMode = useColorMode();
colorMode.value = 'light';
</script>
