<template>
	<section id="statistics">
		<div
			data-aos-delay="100"
			data-aos="fade-left"
			class="flex flex-col items-center justify-between gap-4 rounded-xl border-2 bg-rose-900 px-10 py-10 text-gray-50 md:flex-row xl:px-20 dark:border-gray-50 dark:bg-black-100"
		>
			<div>
				<p class="text-xl font-semibold">Benvenuto, sei il visitatore numero</p>
			</div>
			<div class="flex items-center gap-4">
				<Icon class="h-10 w-10" name="solar:eye-linear" />
				<p class="text-center text-xl font-semibold">
					{{ numero_visualizzazioni }}
				</p>
			</div>
		</div>
	</section>
</template>

<script setup lang="ts">
const numero_visualizzazioni = ref<number>(1300);

onMounted(async () => {
	const data = await $fetch<{numero_visualizzazioni: number}>('https://salvobeatoparrucchieri.com/db.php');

	numero_visualizzazioni.value = data.numero_visualizzazioni;
});
</script>
