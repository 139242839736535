<template>
	<section id="products">
		<div class="w-full" data-aos="fade-up">
			<BaseIntestazione :intestazione="intestazione" />
		</div>
		<div class="mt-12 grid gap-12 lg:grid-cols-2 lg:gap-20">
			<div class="order-2 grid grid-cols-2 grid-rows-2 gap-4 lg:order-1">
				<BasePicture
					data-aos-delay="200"
					data-aos="fade-left"
					class="row-span-2 h-full w-full rounded-lg"
					src="/images/product/img7.webp"
					alt="protoplasmina prestige oil"
					title="protoplasmina prestige oil"
					aria-label="protoplasmina prestige oil"
					width="600"
					height="600"
				/>

				<BasePicture
					data-aos-delay="300"
					data-aos="fade-right"
					class="h-full max-h-[300px] w-full rounded-lg"
					src="/images/product/img8.webp"
					alt="Protoplasmina product"
					title="Protoplasmina product"
					aria-label="Protoplasmina product"
					width="300"
					height="300"
				/>

				<BasePicture
					data-aos-delay="400"
					data-aos="fade-left"
					class="h-full max-h-[300px] w-full rounded-lg"
					src="/images/product/img9.webp"
					alt="Protoplasmina product"
					title="Protoplasmina product"
					aria-label="Protoplasmina product"
					width="300"
					height="300"
				/>
			</div>

			<div class="flex items-center lg:order-2" data-aos-delay="100" data-aos="fade-left">
				<div class="flex flex-col gap-8">
					<p class="text-3xl font-extrabold sm:text-4xl">
						Uomo - Cella: La Linea della Barba di Alta Qualità
					</p>

					<div class="text-xl font-light">
						<p class="mb-3 font-bold">Prodotti per la Barba</p>

						<p>
							Per l'uomo che esige solo il meglio, presentiamo la nuova linea di prodotti per la barba a
							marchio Cella. Conosciuti per la loro tradizione e qualità superiore, i prodotti Cella sono
							formulati per offrire una cura della barba impeccabile. Che tu abbia una barba folta o solo
							un accenno, i nostri oli, balsami e creme garantiscono idratazione, morbidezza e un aspetto
							curato.
						</p>

						<p class="mb-3 mt-6 font-bold">Prodotti per i Capelli</p>

						<p>
							Oltre alla linea barba, offriamo anche una selezione di prodotti per capelli di alta
							qualità. La gamma Cella include shampoo, balsami e trattamenti specifici per ogni esigenza,
							progettati per mantenere i tuoi capelli forti, sani e ben curati. Con ingredienti di alta
							qualità e formule avanzate, i prodotti Cella rappresentano l'eccellenza nella cura dei
							capelli maschili.
						</p>
					</div>
					<div>
						<span class="text-rose-900 dark:text-secondary">
							<nuxt-link
								:to="{path: '/', hash: '#contact'}"
								class="flex items-center gap-2 text-sm font-extrabold hover:opacity-75 md:text-xl"
							>
								<Icon class="h-5 w-5" name="solar:link-broken" />
								Via Giovanni Boccaccio, 104/C - 95047 Paternò (CT)
							</nuxt-link>
						</span>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup lang="ts">
const intestazione = reactive({
	name: 'Prodotti',
	title: 'Nuovi ed esclusivi prodotti',
	description:
		'Siamo entusiasti di presentarvi la nostra gamma di prodotti, progettati per migliorare la vostra esperienza di cura personale e garantire il benessere della vostra pelle e dei vostri capelli.'
});
</script>
