import revive_payload_client_4sVQNw7RlN from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gab9g3XuEx from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_X7whXyrIWR from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import siteConfig_JRId4KOeUL from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_lla9AZgoHk from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import plugin_WLsn00x1qh from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _02_VueSplide_AUba0Q4mI2 from "/home/runner/work/salvobeatoparrucchieri.com/salvobeatoparrucchieri.com/plugins/02.VueSplide.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_gab9g3XuEx,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  defaultsWaitI18n_lla9AZgoHk,
  plugin_WLsn00x1qh,
  _02_VueSplide_AUba0Q4mI2
]